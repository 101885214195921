import { Link } from "react-router-dom"

export function NavLinkMaker(props){

    const tagTempletes = {

        vanilla: info =>{
            return <a rel="nofollow" href={info.path} target="_blank">{props.children}</a>
        },
    }

    const tagMaker=(data)=>{
        return (
            data.blank
                ? <a href={data.path} target="_blank" rel="noreferrer noopner">{props.children}</a>
                : <Link to={data.path}>{props.children}</Link>
        )
    }

    return (
        props.data.tagForced
            ? tagTempletes[props.data.requireTag](props.data)
            : tagMaker(props.data)
    )
}
