import { Link } from "react-router-dom"

import {hvnUriRoot, shopInfo, linkNavs, areaName } from "../core/defines";
import { telBlockPc } from "../core/core"
import { NavLinkMaker } from "../hepers/blockmaker";

function reserveBtn(hvnId){

    let id =""
    let linkTxt = "ネット予約"
    if(hvnId){
        id= `?girl_id=${hvnId}`
        linkTxt= "このキャストを予約"
    }

    return(
        <nav className="btn-fix reserve">
            <a href={`${hvnUriRoot}/A6ShopReservation/${id}`} target="_blank" rel="noreferrer noopner">{linkTxt}</a>
        </nav>
    )
}
function telBtn(tel){

    return(
        <nav className="btn-fix tel">
            <a className="btn-tel" href={"tel:"+ tel }>電話予約</a>
        </nav>
    )
}


function Footer({hvnId=null}){
    return(
        <footer className="txt-center">
            <section>
                <div className="footer-bnr">
                    <a href="https://men.marineblue-g.com">
                        <img 
                            src="/img/20231019-s-rec.jpg" alt="男女スタッフ大募集！ 店舗スタッフ未経験時給50万円～ 経験者月給80万円～" />
                    </a>
                </div>

                <span>
                    <a href="/home" class="logo-btn">
                        <img className="logo" src="/img/logo-tsuchiura.svg?v=2" />
                    </a>
                </span>
                <nav className="pc box">
                    <ul className="navs">
                        {linkNavs.map((link)=>{
                            return(
                                <li className="link">
                                    <NavLinkMaker data={link}>{ link.name }</NavLinkMaker>
                                </li>
                            )
                        })}
                    </ul>
                </nav>
                <div>
                    {telBlockPc()}
                </div>
                <p className="subtitle">{ areaName }のソープランド 【{ shopInfo.name }】</p>
                <span className="copy">Copyright&copy;{ shopInfo.name } All Rights Reserved.</span>
            </section>
            <div id="btn-fix-parent">
                {telBtn(shopInfo.tel)}
                {reserveBtn(hvnId)}
            </div>
        </footer>
    );
}
export default Footer;