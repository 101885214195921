import { shopInfo, areaName, hvnUriRoot, slideArr } from "./core/defines";
const navs = [];

const enterenceBanner = slideArr[0]

function Enterance(){
    return(
        <article id="entrance" className="txt-center">
            <header>{areaName}のソープランド 【{ shopInfo.name }】</header>
            <picture>
                <img src={enterenceBanner.img.imgPathSp} alt={`${areaName}のソープランド【${shopInfo.name}】`} />
            </picture>
            <div className="wrappar">
                <p className="w-100">
                    <img src="/img/18txt.png" width="61" alt="当サイトはアダルトコンテンツを含みます。18歳未満の方のご利用は固く禁じられています。" />
                </p>
                <p className="kin-txt">
                    当サイトはアダルトコンテンツを含みます。<br/>
                    18歳未満の方のご利用は固く禁じられています。
                </p>
                <a className="exit" href="https://www.yahoo.co.jp" nofollow>18歳未満の方は<u>コチラ</u>からご退出ください。</a>

                <div className="attention-container">
                    <div className="attention-box">
                        当店は暴力団を含む反社会的団体<br/>
                        及びスカウト等との関わりは一切ございません。
                    </div>
                    <div class="attention-wrap">
                        <div class="title">■加盟協会・団体</div>
                        <ul className="group-box">
                            <li>・全日本特殊浴場協会連合会</li>
                            <li>・一般社団法人東京特殊浴場暴力団等排除推進協議会</li>
                            <li>・一般社団法人全国防犯健全協力会</li>
                            <li>・東京防犯健全協力会</li>                        
                        </ul>
                    </div>
                </div>
                
                <nav className="enter">
                    <ul>
                        <li><a className="btn to-home" href="/home"><span>ENTER</span>18歳以上のお客様ページ</a></li>
                    </ul>
                </nav>
                

                <section className="recruit">
                    {/*
                        <ul className="box">
                            <li><a className="btn women" href="https://www.girlsheaven-job.net/tochigi/ma-209/sa-519/tulipgirls/?of=y"><span>女性求人</span>ENTER</a></li>
                            <li><a className="btn men"   href="https://mens-qzin.jp/tochigi/area_09001/detail/utsunomiyatulipgirls/?v=official"><span>男性求人</span>ENTER</a></li>
                        </ul>
                    */
                    }
                </section>
                <section>

                    <div className="bnr-box">
                        <a href="https://marineblue-g.com/" target="_blank" rel="norefarrer noopner">
                            <img src="https://marineblue-g.com/img/bnr-group_20240701.jpg" width="640px" alt="マリングループ" />
                        </a>
                    </div>
                    <div className="bnr-box">
                        <a href="https://www.cityheaven.net/ibaraki/A0802/A080202/tulipgirls_t/?of=y2">
                            <img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ヘブン版はこちら.png" width="640px" />
                        </a>
                    </div>  
                    <div className="bnr-box">
                        <a href="https://www.girlsheaven-job.net/ibaraki/ma-164/sa-425/tulipgirls_t/?of=y">
                            <img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ガールズ版はこちら.png" width="640px" />
                        </a>
                    </div>
                    <div className="bnr-box">
                        <a href="https://mensheaven.jp/17/tulipgirls_t/?of=y">
                            <img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ジョブ版はこちら.png" width="640px" />
                        </a>
                    </div>
                    <div className="bnr-box">
                        <a href="https://www.cityheaven.net/ibaraki/A0802/A080202/shop-list/biz4/" target="_blank">
                            <img src="https://img.cityheaven.net/img/mutual_link/468_68_29.jpg" width="468" height="68" border="0" alt="土浦のソープ｜シティヘブンネット" />
                        </a>
                    </div>
                    <div className="bnr-box">
                        <a href="https://www.girlsheaven-job.net/ibaraki/ma-164/sa-425/" target="_blank">
                            <img src="https://img.girlsheaven-job.net/img/fppc/468_68_29.png" width="468" height="68" border="0" alt="土浦の風俗求人｜ガールズヘブン" />
                        </a>
                    </div>
                    <div className="bnr-box">
                        <a href="https://mensheaven.jp/16/ibaraki/ma-164/sa-425/shop-list/" target="_blank">
                            <img src="https://img.mensheaven.jp/img/fppc/bnr/047.jpg" width="468" height="68" border="0" alt="土浦のスタッフ求人｜ジョブヘブン" />
                        </a>
                    </div>
                    <div className="bnr-box">
                        <a href="https://yarowork.jp/kanto/search/?pref%5B%5D=8" target="_blank">
                            <img src="https://yarowork.jp/img/banner_link/yaroworkbnr_468x60.jpg" width="478" height="61" alt="茨城県の高収入求人情報 野郎WORK"/>
                        </a>
                    </div>
                    <div className="bnr-box">
                        <a href="https://www.hyper-bingo.com" target="_blank">
                            <img src="https://www.hyper-bingo.com/images/shop/shop_mutual_link_banner/binbinweb_a01_468x80.gif" alt="風俗・デリヘル情報 ビンビンウェブ" border="0"/>
                        </a>
                    </div>
                </section>
            </div>
        </article>
    );
}

export default Enterance;