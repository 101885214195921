import React, {createContext, useState, useContext} from "react"
import axios from "axios"

// const newApiRoot = "https://api.marineblue-g.com"

import { diaryApiRoot, hvnUriRoot } from "../core/defines"

// 20240301 フッターの予約ボタン改良に伴い改修・状態管理を変更
const CastDataContext = createContext()
export const useCastData =()=>useContext(CastDataContext)

export default function CastDataProvider({children}){

    const [hvnId, setHvnId]=useState(null)

    const getCastDiary =async(hvnCastId)=>{
        
        const target = `${hvnUriRoot}/girlid-${hvnCastId}/diary/`
        const res= axios({
            method: "get",
            url: `${diaryApiRoot}/diary_cast?t=` + target
        })
            .then((res=>{
                // console.log(res)
                return(res.data)
            }))
            .catch(e=>{
                return []
            })
        return res
    }

    const updateHvnId=(id)=>{
        setHvnId(id)
        return null
    }

    const resetHvnId=()=>{
        setHvnId(null)
        return null
    }

    return(
        <CastDataContext.Provider value={{
            hvnId,
            updateHvnId, resetHvnId, getCastDiary
        }}>
            {children}
        </CastDataContext.Provider>
    )
}