import { hvnApi } from "../core/defines";

function sideNav(){

    return(
        <section id="side-nav" className="layout-box">
                <div className="sp">
                    <article className="wrappar-hvn-diary">
                        <div class="wrapper-title"><h2 class="title">写メ日記</h2></div>
                        <iframe src={hvnApi.Diary} />
                    </article>
                    <article className="wrappar-hvn-video">
                        <div class="wrapper-title"><h2 class="title">MOVIE</h2></div>
                        <iframe src={hvnApi.Video} />
                    </article>
                </div>
                <article className="wrappar-hvn-comments">
                    <iframe src={hvnApi.Comment} />
                </article>
        </section>
    );
}
export default sideNav;
